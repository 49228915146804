/*
  사용자의 사용자정의 필드 업데이터 ( )
*/
import React, { useEffect } from "react";
import {
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

import "./CreateUser.css";
import config from '../config';

export default function Signup(props) {

  const [fields, handleFieldChange] = useFormFields({
    username: "",
    poj_no: "",   //  도도부현 (2자리)
    rez_type: "", // 점수표 (1자리)
    hosp_cd: "",  // 의료기관코드(7자리)
    mdi_id: ""    // 고객ID (OWL : cstm_id)
  });

  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.poj_no.length===2 &&
      fields.rez_type.length===1 &&
      fields.hosp_cd.length===7 &&
      fields.mdi_id.length===10
    );
  }

  // user session 체크
  useEffect(() => {
    onLoad();
  }, []);
  /*------------------------------------------------------
  -- onLoad
  ------------------------------------------------------*/
  async function onLoad() {
    try {
      console.log("onLoad----");
      const validationcode = prompt('運営管理者の [検証コード]を入力してください。' ,'');
      console.log ( "verification code : " + validationcode ) ;
      if ( validationcode !== 'mdi20190116' ){
        props.history.push("/");
      }
    }
    catch(e) {
      console.log (e);
      alert(e);
  }
};
  /*------------------------------------------------------
  -- 사용자 정의 속성 변경 클릭
  ------------------------------------------------------*/
  async function handleSubmit(event) {
    event.preventDefault();

    var AWS = require('aws-sdk');
    try {

      AWS.config.update({
        region: config.cognito.REGION,
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID
      });

      var cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({ apiVersion: '2016-04-18'});
      //
      var params = {
        UserAttributes: [
          { Name: "custom:poj_no", Value: fields.poj_no          },
          { Name: "custom:rez_type", Value: fields.rez_type          },
          { Name: "custom:hosp_cd", Value: fields.hosp_cd       },
          { Name: "custom:mdi_id", Value: fields.mdi_id       }
        ],
        Username: fields.username,                // ID
        UserPoolId : config.cognito.USER_POOL_ID
      };
      console.log("Sending params to cognito: " + JSON.stringify(params));

      cognitoIdentityServiceProvider.adminUpdateUserAttributes( params, function (error, data){
        if (error) {
          console.log ( error) ;
          console.log("Error adding user to cognito: " + error, error.stack);
        } else {
          console.log(data);
          alert ( "ユーザーのカスタム属性を変更されました。") ;
          //props.history.push("/adminUpdateUserAttributes");
          //props.history.push("/");
          // form Reset 하면 / 로 갈 필요 없는데.. 나중에..
        }
      });

    } catch (e) {
      console.log(e.message);
      alert ( e.message) ;

    }
    // 사용자 가입 End
  }
  /*---------------------------------------------------------------------------
  // 사용자 등록 화면
  ---------------------------------------------------------------------------*/
  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="small">
          <ControlLabel>ユーザーＩＤ</ControlLabel>
          <FormControl
            autoFocus
            type="username"
            value={fields.username}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="poj_no" bsSize="small">
          <ControlLabel>都道府県 （数字2桁）</ControlLabel>
          <FormControl
            type="poj_no"
            onChange={handleFieldChange}
            value={fields.poj_no}
          />
        </FormGroup>
        <FormGroup controlId="rez_type" bsSize="small">
          <ControlLabel>点数表 （数字1桁、医科「１」、歯科「３」）</ControlLabel>
          <FormControl
            type="rez_type"
            onChange={handleFieldChange}
            value={fields.rez_type}
          />
        </FormGroup>
        <FormGroup controlId="hosp_cd" bsSize="small">
          <ControlLabel>医療機関番号 （数字7桁）</ControlLabel>
          <FormControl
            type="hosp_cd"
            onChange={handleFieldChange}
            value={fields.hosp_cd}
          />
        </FormGroup>
        <FormGroup controlId="mdi_id" bsSize="small">
          <ControlLabel>顧客番号（10桁）</ControlLabel>
          <FormControl
            type="mdi_id"
            onChange={handleFieldChange}
            value={fields.mdi_id}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          disabled={!validateForm()}
        >
          カスタム属性変更
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      { renderForm()}
    </div>
  );
}
