/*
  초기화면 ( home )
    OWL Start

*/
import React from "react";
import "./Home.css";
import LoaderButton from "../components/LoaderButton";

import { Auth } from 'aws-amplify';
import config from '../config';

export default function Home(props) {

  function handleClick(e) {
    e.preventDefault();
    // 생성정보 확인하여 팝업 띄우기.
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then( user => {
      var arg = "a=&b=&c="+user.username+"&d="+user.attributes.name+"&e="+user.attributes.phone_number+"&f="+user.attributes.email ;
      var url= config.owl_callback.starter+"?"+arg ; // awsin.mics
      //console.log (url) ;
      window.open(url,
        "CheckEyeDx　レセプトチェッカ",
        'scrollbars=no,status=no,titlebar=no,toolbar=no,status=no,resizable=yes,menubar=no,location=no,width=1024, height=700'
      );

    }).catch( err => {
      console.log("catch( err => {");
      console.log(err);
      //alert ("ログインをお願いします。") ;
      props.history.push("/login");
     });

  }

  return (
    <div className="Home">
      <div className="lander">
        <h1>医療機関向けクラウド版チェックアイDXレセプトチェッカー</h1>
          <LoaderButton block onClick={handleClick} bsSize="large" >
             Ｓｔａｒｔ
          </LoaderButton>

        <div class="wrap">
          <div class="box">
            <div className="in">
              <h4 className="center_text">&lt;大切なお知らせ&gt;</h4>
              <h4>
                日頃よりチェックアイDXをご利用いただき誠にありがとうございます。<br/>
                現在、Googlechromeでレセプトチェックを行う際に、レセプトデータの取り込みができない事象が報告されています。<br/>
                この件については、弊社でも調査中ではありますが、Googlechromeの問題のようです。<br/>
                対応策として、他のインターネットブラウザ（MicrosoftEdge等）でお試しいただくことでデータ取り込みを行うことができます。<br/>
                お手数をおかけいたしますが、何卒よろしくお願いいたします。<br/>
                <br/>
                2024.12.2 チェックアイDXサポートデスク
              </h4>
            </div>
            <div><br/></div>
            <div className="in">
              <h4 className="center_text">～操作方法や点検内容についてお困りの場合は～<br/><br/>
                チェックアイDX　お問合せフォーム<br/>
                &nbsp;&nbsp; （ログイン後、画面右上の &nbsp;<img style={{height: 1 + 'em'}}  src="supportDesk.png"/>　のアイコンから）<br/>
                または　E-Mail : checkeye-dx@nichiigakkan.co.jp<br/>
                <br/><br/>
                ～システム障害の場合は～<br/>
                TEL：03-5287-5010　平日10時～17時<br/>
                （土日・祝祭日・夏季冬季休業期間を除く）
              </h4>
            </div>
          </div>
        </div>
  
      </div>
    </div>

  );
}
