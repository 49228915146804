import React, { useState } from "react";
import { Auth } from "aws-amplify";
import {
  HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

import "./Signup.css";

import config from '../config';

export default function Signup(props) {
  // 필드 : username, email, phone_number, password, confirmPassword, confirmationCode
  // 등록 필수 항목 : email, phone_number, name
  // poj_no, rez_type, hosp_cd -> cstm_id ( OWL 용)
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    phone_number: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
    name: "",
    poj_no: "",   //  도도부현 (2자리)
    rez_type: "", // 점수표 (1자리)
    hosp_cd: ""   // 의료기관코드(7자리)
  });
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.email.length > 0 &&
      fields.phone_number.length > 0 &&
      fields.name.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }
  /*------------------------------------------------------
  -- 사용자 등록 버튼 클릭
  ------------------------------------------------------*/
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    //
    // 사용자 가입 start
    try {
      /*
      const newUser = await Auth.signUp({
        username: fields.username,
        password: fields.password,
        attributes: {
          email: fields.email,
          phone_number: fields.phone_number,
          name : fields.name,
          'custom:poj_no' : fields.poj_no,
          'custom:rez_type' : fields.rez_type,
          'custom:hosp_cd' : fields.hosp_cd
        }
        //,validationData: {
        //  email_verified: "true"
        //}  //optional
      });
      */
      setIsLoading(false);
      setNewUser(newUser);

      //TEST
      const url= config.owl_callback.createUser ;

      alert ( url +"," +fields.poj_no +","
        + fields.rez_type +"," +fields.hosp_cd );
      setIsLoading(false);
      // OWL 사용자 등록
/*      fetch('http://localhost:8080/owl/psg6g2IQ80deDw95JckR+WzNUNMqxlIOX5Wed9n+96w=.mics?mode=adminResiterUser&userid=bsw1&usernm=BAE&mail_addr=baedol59@plesion.co.kr&user_registno=+81102982106&cstm_id=0110123456')
        .then(response => response.json())
        .then(json => console.log(json))
*/
      /* 전송방식은 POST */
      const postData = {
        mode: "adminResiterUser",
        userid : fields.username,
        usernm : fields.name,
        mail_addr: fields.email,
        user_registno: fields.phone_number,
        cstm_id: fields.poj_no+""+fields.rez_type+""+fields.hosp_cd
      } ;
      alert ( JSON.stringify(postData) ) ;

//      new Promise( (reslove,reject)=>{
//        return
      const response = await fetch ( url, {
            method: "POST",                // *GET, POST, PUT, DELETE, etc.
            mode: 'no-cors' ,             // no-cors, cors, *same-origin
            //credentials: 'same-origin' ,  // include, *same-origin, omit
            headers:{
                'Access-Control-Allow-Origin':'*',
                'Accept': 'application/json',
                'content-type':'application/json; charset=UTF-8'
              },
            body : JSON.stringify(postData)
        });
        console.log ( response) ;

        const responseOK = response && response.ok ;
        if (responseOK){
          let data = await response.json();
          console.log ( data ) ;
        }
        /*
        .then(response=>response.json())
        .then(json=>console.log(json))
        .catch(e =>console.log(e))
        */
//      });
//        .catch(err=>{
//          //console.log(err);
//          reject(err);
//        });
//      }).catch(err => {
//          console.log(err);
//      });
    } catch (e) {
      alert(e.message);
      setIsLoading(false);
    }
    // 사용자 가입 End
  }
  /*------------------------------------------------------
  -- 인증코드 등록
  ------------------------------------------------------*/
  async function handleConfirmationSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    // 사용자 인증코드
    try {
      await Auth.confirmSignUp(fields.username, fields.confirmationCode);
      // 바로 로그인 할 경우, UserNotFoundException 이 날라온다.
      // 따라서 signin 하지 말고, 화면 이동 하도록 한다.
      /*
      await Auth.signIn(fields.email, fields.password);
      props.userHasAuthenticated(true);
      props.history.push("/");*/
      props.history.push("/login");
    } catch (e) {
      console.log (e);
      alert(e.message);
      setIsLoading(false);
    }
    // 사용자 인증코드
  }

  /*---------------------------------------------------------------------------
  // 인증코드 등록 화면 ( 사용자등록 정보가 있을 경우)
  ---------------------------------------------------------------------------*/
  function renderConfirmationForm() {
    return (
      <form onSubmit={handleConfirmationSubmit}>
        <FormGroup controlId="confirmationCode" bsSize="large">
          <ControlLabel>ＯＷＬユーザー作成：：：検証コード</ControlLabel>
          <FormControl
            autoFocus
            type="tel"
            onChange={handleFieldChange}
            value={fields.confirmationCode}
          />
        <HelpBlock>ユーザー作成確認[検証コード]を入力してください。</HelpBlock>
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateConfirmationForm()}
        >
          Verify
        </LoaderButton>
      </form>
    );
  }
  /*---------------------------------------------------------------------------
  // 사용자 등록 화면
  ---------------------------------------------------------------------------*/
  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="small">
          <ControlLabel>ユーザーＩＤ</ControlLabel>
          <FormControl
            autoFocus
            type="username"
            value={fields.username}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="name" bsSize="small">
          <ControlLabel>ユーザー名 </ControlLabel>
          <FormControl
            type="name"
            value={fields.name}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="small">
          <ControlLabel>E メール (Email)</ControlLabel>
          <FormControl
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="phone_number" bsSize="small">
          <ControlLabel>電話番号 (Phone number)</ControlLabel>
          <FormControl
            type="phone_number"
            value={fields.phone_number}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="small">
          <ControlLabel>パスワード (Password)</ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="confirmPassword" bsSize="small">
          <ControlLabel>確認パスワード (Confirm Password)</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.confirmPassword}
          />
        </FormGroup>
        <FormGroup controlId="poj_no" bsSize="small">
          <ControlLabel>都道府県 （数字2桁）</ControlLabel>
          <FormControl
            type="poj_no"
            onChange={handleFieldChange}
            value={fields.poj_no}
          />
        </FormGroup>
        <FormGroup controlId="rez_type" bsSize="small">
          <ControlLabel>点数表 （数字1桁、医科「１」、歯科「３」）</ControlLabel>
          <FormControl
            type="rez_type"
            onChange={handleFieldChange}
            value={fields.rez_type}
          />
        </FormGroup>
        <FormGroup controlId="hosp_cd" bsSize="small">
          <ControlLabel>医療機関番号 （数字7桁）</ControlLabel>
          <FormControl
            type="hosp_cd"
            onChange={handleFieldChange}
            value={fields.hosp_cd}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
