/*
  관리자 :  사용자 생성
*/
import React, { useState, useEffect } from "react";
//import React, { useState } from "react";
//import { Auth } from "aws-amplify";
import {
  //HelpBlock,
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

import "./CreateUser.css";
import config from '../config';

//------------------------------------------------------------------------------
// SDK
//const AWS = require('aws-sdk/dist/aws-sdk-react-native');
//const AWS = require('aws-sdk');
//const AmazonCognitoIdentity = require('amazon-cognito-identity-js');
//------------------------------------------------------------------------------

export default function Signup(props) {
  // 필드 : username, email, phone_number, password,
  // 등록 필수 항목 : email, phone_number, name
  // poj_no, rez_type, hosp_cd , mdi_id -> cstm_id ( OWL 용)
  const [fields, handleFieldChange] = useFormFields({
    username: "",
    email: "",
    phone_number: "",
    password: "",
    name: "",
    poj_no: "",   // 도도부현 (2자리)
    rez_type: "", // 점수표 (1자리)
    hosp_cd: "",  // 의료기관코드(7자리)
    mdi_id: ""    // 고객ID (OWL : cstm_id)
  });
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.username.length > 0 &&
      fields.email.length > 0 &&
      fields.phone_number.length > 0 &&
      fields.name.length > 0 &&
      fields.password.length > 0 &&
      fields.poj_no.length===2 &&
      fields.rez_type.length===1 &&
      fields.hosp_cd.length===7 &&
      fields.mdi_id.length===10
    );
  }
  // user session 체크
  useEffect(() => {
    onLoad();
  }, []);
  /*------------------------------------------------------
  -- onLoad
  ------------------------------------------------------*/
  async function onLoad() {
    try {
      console.log("onLoad----");
      const validationcode = prompt('運営管理者の [検証コード]を入力してください。' ,'');
      console.log ( "verification code : " + validationcode ) ;
      if ( validationcode !== 'mdi20190116' ){
        props.history.push("/");
      }
    }
    catch(e) {
      console.log (e);
      alert(e);
  }
};
  /*------------------------------------------------------
  -- 사용자 등록 버튼 클릭
  ------------------------------------------------------*/
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    //
    // 사용자 가입 start
/*  // 환경변수 사용 X
    //require('dotenv').config();
    dotenv.config();
*/
    var AWS = require('aws-sdk');

    try {

      AWS.config.update({
        region: config.cognito.REGION,
        IdentityPoolId: config.cognito.IDENTITY_POOL_ID
      });

      var cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({ apiVersion: '2016-04-18'});
      //
      var params = {
        DesiredDeliveryMediums: ["SMS"],     // 환영메세지를 보낼때 사용 , Valid Values: SMS | EMAIL
        //MessageAction : "RESEND",            //RESEND | SUPPRESS // SUPPRESS:Do not send welcome email
        UserPoolId: config.cognito.USER_POOL_ID,
        Username: fields.username,          // ID
        TemporaryPassword: fields.password, //"1234qwerASDF",
        UserAttributes: [
          { Name: "email", Value: fields.email         },
          { Name: "phone_number", Value: fields.phone_number          },
          { Name: "name", Value: fields.name          },
          { Name: "email_verified", Value: "true"          }, // Don't verify email addresses
          { Name: "custom:poj_no", Value: fields.poj_no          },
          { Name: "custom:rez_type", Value: fields.rez_type          },
          { Name: "custom:hosp_cd", Value: fields.hosp_cd       },
          { Name: "custom:mdi_id", Value: fields.mdi_id       }
        ]
      };
      console.log("Sending params to cognito: " + JSON.stringify(params));

      cognitoIdentityServiceProvider.adminCreateUser( params, function (error, data){
        if (error) {
          console.log ( error) ;
          console.log("Error adding user to cognito: " + error, error.stack);
        } else {
          console.log(data);
          alert ( "ユーザーが作成されました。") ;
          props.history.push("/adminCreateUser");
        }
      });
      setIsLoading(false);
    } catch (e) {
      console.log(e.message);
      alert ( e.message) ;
      setIsLoading(false);
    }
    // 사용자 가입 End
  }

  /*---------------------------------------------------------------------------
  // 사용자 등록 화면
  ---------------------------------------------------------------------------*/
  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="small">
          <ControlLabel>＊ユーザーＩＤ</ControlLabel>
          <FormControl
            autoFocus
            type="username"
            value={fields.username}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="name" bsSize="small">
          <ControlLabel>＊ユーザー名 </ControlLabel>
          <FormControl
            type="name"
            value={fields.name}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="email" bsSize="small">
          <ControlLabel>＊E メール (Email)</ControlLabel>
          <FormControl
            type="email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="phone_number" bsSize="small">
          <ControlLabel>＊電話番号 (Phone number)</ControlLabel>
          <FormControl
            type="phone_number"
            value={fields.phone_number}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="password" bsSize="small">
          <ControlLabel>＊パスワード </ControlLabel>
          <FormControl
            type="password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </FormGroup>
        <FormGroup controlId="poj_no" bsSize="small">
          <ControlLabel>＊都道府県 （数字2桁）</ControlLabel>
          <FormControl
            type="poj_no"
            onChange={handleFieldChange}
            value={fields.poj_no}
          />
        </FormGroup>
        <FormGroup controlId="rez_type" bsSize="small">
          <ControlLabel>＊点数表 （数字1桁、医科「１」、歯科「３」）</ControlLabel>
          <FormControl
            type="rez_type"
            onChange={handleFieldChange}
            value={fields.rez_type}
          />
        </FormGroup>
        <FormGroup controlId="hosp_cd" bsSize="small">
          <ControlLabel>＊医療機関番号 （数字7桁）</ControlLabel>
          <FormControl
            type="hosp_cd"
            onChange={handleFieldChange}
            value={fields.hosp_cd}
          />
        </FormGroup>
        <FormGroup controlId="mdi_id" bsSize="small">
          <ControlLabel>＊顧客番号（10桁）</ControlLabel>
          <FormControl
            type="mdi_id"
            onChange={handleFieldChange}
            value={fields.mdi_id}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          isLoading={isLoading}
          disabled={!validateForm()}
        >
          ユーザー登録
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="CreateUser">
      { renderForm() }
    </div>
  );
}
