export default {
  s3: {
    REGION: "ap-northeast-1",
    BUCKET: "checkeyedx-upload"
  },
  cognito: {
    REGION: "ap-northeast-1",
    // CheckEyeDx ::: checkeyedx-real, checkeyedx-app, checkeyedx-app-federated( 6월19일 새로생성)
    USER_POOL_ID: "ap-northeast-1_1UFzpjg3J",                                   // checkeyedx-real
    APP_CLIENT_ID: "3g9vshsvc1i5eqblc9ecmjq13j",                                // checkeyedx-app
    IDENTITY_POOL_ID: "ap-northeast-1:fb0e3005-ad3c-4aef-9b5b-f3f60d305efd"     // checkeyedx-app-federated
  },
  //callback URL
  owl_callback:{
    //
    starter : "https://rc.checkeyedx.com/owl/awsin.mics" ,
    createUser : "https://rc.checkeyedx.com/owl/psg6g2IQ80deDw95JckR+WzNUNMqxlIOX5Wed9n+96w=.mics"
    /* local dev
    starter : "http://localhost:8080/owl/awsin.mics",
    createUser : "http://localhost:8080/owl/psg6g2IQ80deDw95JckR+WzNUNMqxlIOX5Wed9n+96w=.mics"
    */
    // #4285, Add 셧다운예정시각, 작업 안내 공지 팝업 여부 yyyyMMddHH.
    ,shutdownDateTime : "2024121423"
    ,jobDateTime : "「１2/１4（土曜日）２３：００～２４：００は "
  }
};
