import React, { useState } from "react";
import { Auth } from "aws-amplify";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

import config from '../config';

import "./Login.css";


export default function Login(props) {

  const [isLoading, setIsLoading] = useState(false);  // isLoding 여부

  const [fields, handleFieldChange] = useFormFields({
    username: "",
    password: "",
    verifycode: "" /* verify code 관련 2020.06.01 Add */
  });

  function validateForm() {
    return fields.username.length > 0 && fields.password.length > 0;
  }
  /*------------------------------------------------------*/
  /* verify code 관련 2020.06.01 Add */
  /*------------------------------------------------------*/
  function verifyValidate() {
    return fields.verifycode.length ===6 ;
  }

  const [isVerifyLoading, setIsVerifyLoading] = useState(false);  // 검증코드 입력 div  여부
  const verifyStyle = isVerifyLoading? {} : {display: 'none'} ; // 초기에는 검증 관련 안보이게 처리.

  const loginStyle = isLoading? {display: 'none'} : {display: ''} ;    //

  var passSec = 180 ;
  var intervalID =null;
  var moving = false ;

  const [stime, setStime] = useState(passSec);
  const [isEmail, setIsEmail] = useState(false);  // isEmail 여부

  // OWL 시스템 호출
  function callOWL(a_user) {

    Auth.currentAuthenticatedUser({
      bypassCache: true  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    }).then( user => {
      //console.log ( user ) ;
      var arg = "a=&b=&c="+user.username+"&d="+user.attributes.name+"&e="+user.attributes.phone_number+"&f="+user.attributes.email ;
      var url= config.owl_callback.starter+"?"+arg ; // awsin.mics
      //console.log (url) ;
      window.open(url,
        "Online Wisdom Libray Beta Server",
        'scrollbars=no,status=no,titlebar=no,toolbar=no,status=no,resizable=yes,menubar=no,location=no,width=1024, height=700'
      );
    }).catch( err => {
      console.log("catch( err => {");
      console.log(err);
      alert (err + "（Ｓｔａｒｔ）を実行してください。") ;
      props.history.push("/");
     });
  }

  /*------------------------------------------------------
  -- 로그인 버튼 클릭
  ------------------------------------------------------*/
  async function handleSubmit(event) {
    console.log ( "moving : " + moving );
    if (moving) return ;
    event.preventDefault();
    setIsLoading(true); // isLoding 여부

    try {
      const user = await Auth.signIn(fields.username, fields.password);  // AWS
      // 'user' 이라는 이름의 key 에 user 이름의 객체 데이터를 세션에 저장
      //window.sessionStorage.setItem('user', JSON.stringify(user));
      config.cognitoUser = user ; // config 전역변수 사용
      console.log("(1) Auth.signIn : user Infos.");
      console.log ( user ) ;
      console.log("(1) user.challengeName --> " + user.challengeName );
      console.log ( config.cognitoUser ) ;

      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        /* @@@인증 코드 받는 경우, */
        DisplayVerifyCode() ;
      }
      else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        /* @@@ 임시비밀번호 변경
        콘솔 계정상태 ::: FORCE_CHANGE_PASSWORD
        사용자는 유효한 사용자 디렉토리가 Amazon Cognito에 생성되는 경우 첫 번째 로그인 시도 동안
        새로운 암호와 필수 속성을 제공하도록 요청받을 것이다.
        이 시나리오 중에 사용자가 입력한 새로운 비밀번호를 처리하기 위해 다음과 같은 방법을 호출할 수 있다.
        개정 03.20 - 새로운 비밀번호 넣는 거를 없애고, 임시비밀번호를 컨펀하도록 한다.
         사유 : 새로운 비밀번호를 넣을 경우, 지속적으로 InvalidPasswordException 가 나고 등록이 안됨.
        */
        console.log ( user.challengeName );
        const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
        console.log ( "user.challengeParam  --> "  );
        console.log ( user.challengeParam ) ;
        //console.log ( "config.cognitoUser -->") ;
        //console.log ( config.cognitoUser ) ;

        // 개정 03.20 새로운 비밀번호 받는 부분 제거.
        //const newPassword = prompt('新しいパスワードを入力してください。' ,'');
        console.log ( user.challengeParam.userAttributes ) ;
        console.log ( user.challengeParam.userAttributes.email ) ;
        console.log ( user.challengeParam.userAttributes.email_verified ) ;
        console.log ( user.challengeParam.userAttributes.phone_number ) ;
        console.log ( user.challengeParam.userAttributes.phone_number_verified ) ;
        const newPassword = fields.password ;
        //const _email = user.challengeParam.userAttributes.email ;
        //const _phone_number = user.challengeParam.userAttributes.phone_number ;
        var _option =null ;
        //const _optionEmail = { name: fields.username, email: user.challengeParam.userAttributes.email  } ;
        //const _optionPhone_number = { name: fields.username, phone_number: user.challengeParam.userAttributes.phone_number } ;

        if ( user.challengeParam.userAttributes.email_verified ==="true" ){
          _option = { name: fields.username, email: user.challengeParam.userAttributes.email  } ;
        }else{
          _option = { name: fields.username, phone_number: user.challengeParam.userAttributes.phone_number } ;
        }
        console.log ( _option ) ;
        //return ;
        Auth.completeNewPassword(
          user,
          newPassword,  // new password
          // OPTIONAL, the required attributes : beta 서버의 경우
          //_option
          { name: fields.username }
        ).then(user=>{
          // at this time the user is logged in if no MFA required
          console.log ( "completeNewPassword 이후.. user.challengeName : " + user.challengeName );
          console.log(user);
          if (user.challengeName==="SMS_MFA"){
            DisplayVerifyCode() ;
          }
          // 2021.12.03 Add : 이메일이나 전화번호를 필수 해제하고 등록할 경우, undefinded 로 넘어오네...
          else if (user.challengeName===undefined){
            // 정상 로그인으로 인식. ( The user directly signs in)
            console.log("The user directly signs in : 정상 로그인 ");
            // OWL Start - 로그인 확인되면 팝업띄우기.
            props.userHasAuthenticated(true);
            callOWL(user) ;
            setIsLoading(false);  // isLoding 여부
            props.history.push("/");
          }
        }).catch(err =>{
          if (err.code === 'InvalidPasswordException') {
            alert ( "提供されたパスワードは、セキュリティ上の理由により使用できません。") ;
          }else {
            alert ( err.message + "("+err.code+")") ;
          }
          console.log(err) ;
        });

      }else if (user.challengeName === 'MFA_SETUP') {
        /* MFA 메서드가 TOTP일 때 발생한다.
           사용자는 TOTP를 사용하기 전에 TOTP를 설정해야 한다.
           자세한 내용은 활성화 MFA 부품을 확인
        */
        //Auth.setupTOTP(user);
      }
      else {
        // 정상 로그인 ( The user directly signs in)
        console.log("The user directly signs in : 정상 로그인 ");
        // OWL Start - 로그인 확인되면 팝업띄우기.
        props.userHasAuthenticated(true);
        callOWL(user) ;
        setIsLoading(false);  // isLoding 여부
        props.history.push("/");

      }

      setIsLoading(false);  // isLoding 여부

    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
          // The error happens if the user didn't finish the confirmation step when signing up
          // In this case you need to resend the code and confirm the user
          // About how to resend the code and confirm the user, please check the signUp part
          alert ( "登録されたユーザが存在しません。\n" + err.message ) ;
      } else if (err.code === 'PasswordResetRequiredException') {
          // The error happens when the password is reset in the Cognito console
          // In this case you need to call forgotPassword to reset the password
          // Please check the Forgot Password part.
          alert ( "Please check the Forgot Password \n" + err.message ) ;
      } else if (err.code === 'NotAuthorizedException') {
          // The error happens when the incorrect password is provided
          alert ( "ユーザ名またはパスワードが正しくないです。\n" + err.message ) ;
      } else if (err.code === 'UserNotFoundException') {
          // The error happens when the supplied username/email does not exist in the Cognito user pool
          alert ( "ユーザが存在しません。\n" + err.message ) ;
      } else {
        alert ( err.code+" : "+ err.message ) ;
      }
      console.log (err) ;
      //alert(err.message +"\n" + err.code );
      setIsLoading(false);  // isLoding 여부
    }
  }
  //----------------------------------------------------------------------------
  //  검증코드를 보여주고 대기
  //----------------------------------------------------------------------------
  function DisplayVerifyCode(){
    setIsVerifyLoading(true) ;  // 검증코드 입력 란 표시 O
    console.log ( "intervalID -> " + intervalID ) ;
    _StartProgressTime() ;
    //setIsLoading(false);  // isLoding 여부
  }

  function _StartProgressTime() {
    moving = true ;
    passSec = 180 ;  // 제한시간 3분
  	intervalID = setInterval ( function(){
      if ( passSec==-1) {
        passSec-- ;
        setStime( "Time Over" )  ;
        _StopProgressTime() ;
      }else {
        let min = parseInt( (passSec%3600)/60) ;
        let sec = passSec%60 ;
        min=pad(min,2) ;
        sec=pad(sec,2) ;
        //setStime( passSec )  ;
        setStime( min + ":" + sec )  ;
        passSec-- ;
      }
  	}, 1000 );	// 1초단위로 호출 .
  }
  function _StopProgressTime() {
    moving = false ;
  	if(intervalID != null) {
        clearInterval(intervalID);intervalID=null ;
        setIsVerifyLoading(false) ;  // 검증코드 입력 란 표시 X
      }
  }
  function pad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }
  //----------------------------------------------------------------------------
  // Verify Your Code
  //----------------------------------------------------------------------------
  function handleClick(e) {
    e.preventDefault();
    console.log ( "handleClick 선택....confirmSignIn start " ) ;
    // config 전역변수 사용
    const user = config.cognitoUser ;
    const validationcode = fields.verifycode ;
    console.log ( user )
    console.log ( user.challengeName + " (2)verification code : " + validationcode ) ;

    // If MFA is enabled, sign-in should be confirmed with the confirmation code
    Auth.confirmSignIn(
        user,   // Return object from Auth.signIn()
        validationcode,   // Confirmation code
        user.challengeName // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
    ).then(userData =>{
      // at this time the user is logged in if no MFA required
      console.log ( "confirmSignIn ::: userData --> "  );
      console.log(userData);
      _StopProgressTime() ;  // 검증코드 입력 란 표시 X

      // OWL Start - 로그인 확인되면 팝업띄우기.
      props.userHasAuthenticated(true);
      callOWL(user) ; // Call OWL
      setIsLoading(false);  // isLoding 여부
      props.history.push("/");

    }).catch(err =>{
      console.log(err) ;
      if ( err.code=="CodeMismatchException"){
        alert ( "認証コードが正しくありません。") ;
      }else{
        alert ( err.message) ;
      }
      //props.history.push("/login");
    });

  }

  return (
    <div className="Login">
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="large">
          <ControlLabel>ユーザーＩＤ</ControlLabel>
          <FormControl
            autoFocus type="username" value={fields.username} placeholder="ユーザーＩＤ"
            onChange={handleFieldChange}
          />
        </FormGroup>

        <FormGroup controlId="password" bsSize="large">
          <ControlLabel>パスワード </ControlLabel>
          <FormControl
            type="password" value={fields.password} placeholder="パスワード"
            onChange={handleFieldChange}
          />
        </FormGroup>

        <LoaderButton block type="submit" bsSize="large" isLoading={isLoading} disabled={!validateForm()} style={loginStyle} >
          Login
        </LoaderButton>

      </form>

        <div className="Verify" style={verifyStyle}>
          <FormGroup controlId="verifycode" bsSize="large">
            <ControlLabel>認証コード（６桁） </ControlLabel>
            <FormControl
              type="verifycode"  placeholder=""
              onChange={handleFieldChange}
            />
          </FormGroup>
          <LoaderButton block onClick={handleClick} bsSize="large" disabled={!verifyValidate()} >
            認証コード登録
          </LoaderButton>
          <p className="App-clock">
               認証コードは 3 分間有効です。 {stime}.
          </p>
        </div>

        <div class="wrap">
          <div class="box">
            <div className="in">
              <h4 className="center_text">&lt;大切なお知らせ&gt;</h4>
              <h4>
                日頃よりチェックアイDXをご利用いただき誠にありがとうございます。<br/>
                現在、Googlechromeでレセプトチェックを行う際に、レセプトデータの取り込みができない事象が報告されています。<br/>
                この件については、弊社でも調査中ではありますが、Googlechromeの問題のようです。<br/>
                対応策として、他のインターネットブラウザ（MicrosoftEdge等）でお試しいただくことでデータ取り込みを行うことができます。<br/>
                お手数をおかけいたしますが、何卒よろしくお願いいたします。<br/>
                <br/>
                2024.12.2 チェックアイDXサポートデスク
              </h4>
            </div>
            <div><br/></div>
            <div className="in">
              <h4 className="center_text">～操作方法や点検内容についてお困りの場合は～<br/><br/>
                チェックアイDX　お問合せフォーム<br/>
                &nbsp;&nbsp; （ログイン後、画面右上の &nbsp;<img style={{height: 1 + 'em'}}  src="supportDesk.png"/>　のアイコンから）<br/>
                または　E-Mail : checkeye-dx@nichiigakkan.co.jp<br/>
              <br/><br/>
                ～システム障害の場合は～<br/>
                TEL：03-5287-5010　平日10時～17時<br/>
                （土日・祝祭日・夏季冬季休業期間を除く）
              </h4>
            </div>
          </div>
        </div>
  
    </div>
  );
}
