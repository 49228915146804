/*
  로그인 사용자의 패스워드 변경
*/
//import AWS from "aws-sdk";
import {
  CognitoUserPool,
  //CognitoUserAttribute,
  CognitoUser,
  AuthenticationDetails
} from "amazon-cognito-identity-js";

import React, { useState,useEffect } from "react";
import { Auth } from "aws-amplify";
import {
  FormGroup,
  FormControl,
  ControlLabel
} from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { useFormFields } from "../libs/hooksLib";

import "./CreateUser.css";
import config from '../config';

export default function Signup(props) {

  const [fields, handleFieldChange] = useFormFields({
    oldpassword: "",
    newpassword1: "",
    newpassword2: "",
  });
  //const [isLoading, setIsLoading] = useState(false);
  //const [<상태 값 저장 변수>, <상태 값 갱신 함수>] = useState(<상태 초기 값>);
  const [ curUserId, setCurUserId ] = useState('');  // Log 사용자 userid
  var cUser = null ;

  function validateForm() {
    return (
      fields.oldpassword.length > 0  &&
      fields.newpassword1.length > 0 &&
      fields.newpassword1 === fields.newpassword2
    );
  }

  // user session 체크
  useEffect(() => {
    onLoad();
  }, []);
  /*------------------------------------------------------
  -- onLoad
  ------------------------------------------------------*/
  async function onLoad() {
    try {
      console.log("onLoad ChangePassword ----");
      await Auth.currentSession();  // AWS

      cUser = await Auth.currentAuthenticatedUser();
      //console.log ( cUser ) ;
      //console.log ( cUser.username ) ;
      setCurUserId (cUser.username ) ;  // 화면 셋팅

    }
    catch(e) {
      console.log (e);
      alert(e);
  }
};
  /*------------------------------------------------------
  -- 패스워드 변경 클릭
  ------------------------------------------------------*/
  async function handleSubmit(event) {
    event.preventDefault();

    //setIsLoading(true);
    console.log ( curUserId ) ;
    try {
      // 사용자 패스워드 변경  Start
      const authenticationData = {
          Username : curUserId,
          Password : fields.oldpassword,  // oldpassword
      };
      var authenticationDetails = new AuthenticationDetails(authenticationData);
      //console.log ( authenticationDetails) ;

      const userPool = new CognitoUserPool({
        UserPoolId: config.cognito.USER_POOL_ID,
        ClientId: config.cognito.APP_CLIENT_ID
      });
      const userData = {
        Username : curUserId,
        Pool : userPool
      };
      var cognitoUser = new CognitoUser(userData);

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result1) {
            cognitoUser.changePassword( fields.oldpassword, fields.newpassword1, function(err, result) {
                if (err) {
                  if (err.code === 'InvalidPasswordException') {
                    alert("セキュリティ上の理由によりパスワードを使用できません。\n"
                    +"「最小長：８、数字、大文字、小文字を必要とする」");
                  }else{
                    // InvalidParameterException
                    alert('エラー：パスワード変更に失敗しました。\n'+ err.code );
                  }
                  console.log ( "err" ) ;
                  console.log(err);
                  //setIsLoading(false);  // isLoding 여부
                }
                else{
                  console.log ( "result" ) ;
                  console.log (result ) ;
                  alert ( "パスワードを変更されました。") ;
                  //setIsLoading(false);  // isLoding 여부
                  props.history.push("/");
                }
            });
        },
        onFailure: function(err) {
            console.log ( "onFailure : " + err.code ) ;
            if (err.code === 'NotAuthorizedException') {
                alert ( "パスワードが正しくないです。\n" + err.message ) ;
            }else{
              alert ( err.code+" : "+ err.message ) ;
            }
            //setIsLoading(false);  // isLoding 여부
        },
    });
    // 사용자 패스워드 변경  End

    } catch (e) {
      //setIsLoading(false);  // isLoding 여부
      console.log(e.message);
      alert ( e.message) ;
    }

  }
  /*---------------------------------------------------------------------------
  // 사용자 등록 화면
  ---------------------------------------------------------------------------*/
  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="username" bsSize="small">
          <ControlLabel>ユーザーＩＤ：{curUserId} </ControlLabel>

        </FormGroup>
        <FormGroup controlId="oldpassword" bsSize="small">
          <ControlLabel>現在のパスワード：</ControlLabel>
          <FormControl
            autoFocus
            type="password"
            onChange={handleFieldChange}
            value={fields.oldpassword}
          />
        </FormGroup>
        <FormGroup controlId="newpassword1" bsSize="small">
          <ControlLabel>新パスワード：</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.newpassword1}
          />
        </FormGroup>
        <FormGroup controlId="newpassword2" bsSize="small">
          <ControlLabel>新パスワード（確認用）：</ControlLabel>
          <FormControl
            type="password"
            onChange={handleFieldChange}
            value={fields.newpassword2}
          />
        </FormGroup>
        <LoaderButton
          block
          type="submit"
          bsSize="large"
          disabled={!validateForm()}
        >
          パスワード変更
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
      { renderForm()}
    </div>
  );
}
